import React from 'react';
import logo from './logo.svg';
import './App.css';
import './bundle.css';
import SenzLogo from './img/senzoku_new.webp';
export const VaultComponent: React.FC = () => {
  const vaultPath: any = { vaultPath: 'senzoku_mr' };
  
  return <div id="diamond-vaults" {...vaultPath}></div>;
}

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={SenzLogo} className="App-logo" alt="logo"/>
       
        <VaultComponent />
        
      </header>
    </div>
  );
}

export default App;
