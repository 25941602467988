// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App {
  text-align: center;
  display: flex;
  width: -moz-fit-content;
  width: fit-content;
  margin: 0 auto;
  height: -moz-fit-content;
  height: fit-content;
  align-items: center;
  justify-content: center;
  
  
}


.App-logo {
  animation: fadeIn 1s ease-in;
  height: 28vmin;
  pointer-events: none;
  border-radius: 10px;
  box-shadow: 0 0 10px #880000, 0 0 0px #880000, 0 0 0px #880000, 0 0 0px #880000, 0 0 0px #880000, 0 0 200px #88000057, 0 0 0px #880000, 0 0 400px #88000050;
}




.App-header {
  background-color: #010714;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  padding-top: 70px;
}

.App-link {
  color: #61dafb;
}

.vault-toolbar-container .action-container * .action-container-stake::before {
  content: "test";
}
.vault-toolbar-container .action-container * .action-container-unstake::before {
  content: "UNSTAKE";
}

.title {
  font-size: 30px;
}
.value{
  font-weight: 900;
}
@keyframes fadeIn {
  0% {
      opacity: 0
  }

  100% {
      opacity: 1
  }
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,aAAa;EACb,uBAAkB;EAAlB,kBAAkB;EAClB,cAAc;EACd,wBAAmB;EAAnB,mBAAmB;EACnB,mBAAmB;EACnB,uBAAuB;;;AAGzB;;;AAGA;EACE,4BAA4B;EAC5B,cAAc;EACd,oBAAoB;EACpB,mBAAmB;EACnB,2JAA2J;AAC7J;;;;;AAKA;EACE,yBAAyB;EACzB,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;EACvB,6BAA6B;EAC7B,YAAY;EACZ,iBAAiB;AACnB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,eAAe;AACjB;AACA;EACE,kBAAkB;AACpB;;AAEA;EACE,eAAe;AACjB;AACA;EACE,gBAAgB;AAClB;AACA;EACE;MACI;EACJ;;EAEA;MACI;EACJ;AACF","sourcesContent":[".App {\n  text-align: center;\n  display: flex;\n  width: fit-content;\n  margin: 0 auto;\n  height: fit-content;\n  align-items: center;\n  justify-content: center;\n  \n  \n}\n\n\n.App-logo {\n  animation: fadeIn 1s ease-in;\n  height: 28vmin;\n  pointer-events: none;\n  border-radius: 10px;\n  box-shadow: 0 0 10px #880000, 0 0 0px #880000, 0 0 0px #880000, 0 0 0px #880000, 0 0 0px #880000, 0 0 200px #88000057, 0 0 0px #880000, 0 0 400px #88000050;\n}\n\n\n\n\n.App-header {\n  background-color: #010714;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  font-size: calc(10px + 2vmin);\n  color: white;\n  padding-top: 70px;\n}\n\n.App-link {\n  color: #61dafb;\n}\n\n.vault-toolbar-container .action-container * .action-container-stake::before {\n  content: \"test\";\n}\n.vault-toolbar-container .action-container * .action-container-unstake::before {\n  content: \"UNSTAKE\";\n}\n\n.title {\n  font-size: 30px;\n}\n.value{\n  font-weight: 900;\n}\n@keyframes fadeIn {\n  0% {\n      opacity: 0\n  }\n\n  100% {\n      opacity: 1\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
